import {
    ArrowDownward,
    ArrowUpward,
    Delete,
    Edit
} from "@mui/icons-material";
import { Chip, Link, Radio, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { capitalize } from "lodash";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BiLogInCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import BrokerSelectDialog from "../broker-setup/BrokerSelectDialog";
import ColoredButton from "../common/ColoredButton";
import ConfirmDialog from "../common/ConfirmDialog";
import DialogBoxWrapper from "../common/DialogBoxWrapper";
import FeedbackSnackbar from "../common/FeedbackSnackbar";
import LoadingBackdrop from "../common/LoadingBackdrop";
import NoRowsOverlay from "../common/NoRowsOverlay";
import StyledDataGrid from "../common/StyledDataGrid";
import TradeLegsDetails from "./TradeLegsDetails";
import { useWebSocketContext } from "../../Utils/WebSocketProvider";

export default function SavedStrategiesTable({ savedStrategies }) {

    const { ws } = useWebSocketContext();

    const columns = [
        {
            field: "name",
            headerName: "Strategy Name",
            headerAlign: "left",
            align: "left",
            renderCell: renderStrategyName,
            width: 200,
        },
        {
            field: "instrument",
            headerName: "Instrument",
            width: 150,
        },

        {
            field: "strategy",
            headerName: "Strategy Type",
            renderCell: renderStrategyType,
            width: 200,
        },
        {
            field: "lots",
            headerName: "Lots",
            width: 100,
        },
        {
            field: "csop",
            type: "number",
            headerName: "CSOP",
            width: 135,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "target",
            type: "number",
            headerName: "Target Delta",
            width: 135,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "sl",
            type: "number",
            headerName: "StopLoss Delta",
            width: 135,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: renderStatus,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 135,
            getActions: (params) =>
                params.row.status !== "placed"
                    ? [
                        <GridActionsCellItem
                            icon={<Tooltip title='Edit strategy'><Edit sx={{ color: "black" }} /></Tooltip>}
                            label="Edit"
                            onClick={() => navigate('/strategies/addStrategy', { state: { strategyDetails: params.row, mode: 'edit' } })}
                        />,

                        <GridActionsCellItem
                            icon={<Tooltip title='Delete strategy'><Delete sx={{ color: "black" }} /></Tooltip>}
                            label="Delete"
                            onClick={handleDeleteClick(params.id)}
                        />,
                    ]
                    : [],
        },
    ];

    const handleDeleteClick = (id) => () => {
        new Promise((resolve, reject) => {
            setPromiseArguments({
                promiseArgs: { resolve, reject, id },
                msg: "Are you sure you want to delete this strategy?",
            });
        });
    };

    function renderStrategyType(props) {
        const { value } = props;

        return (
            <div>
                <Chip label={value} color="primary" variant="outlined" />
            </div>
        );
    }

    function renderStrategyName(props) {
        const { value, id } = props;

        return (
            <div>
                <Link
                    sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        textDecoration: "none",
                        borderBottom: "1px solid black",
                    }}
                    component="button"
                    variant="body2"
                    onClick={() => showStrategyLegDetails(id)}
                >
                    {value}
                </Link>
            </div>
        );
    }

    function renderStatus(props) {
        const { value } = props;

        return (
            <div>
                {value === "placed" ? (
                    <Chip label="In execution" color="error" />
                ) : (
                    <Chip label={capitalize(value)} color="success" />
                )}
            </div>
        );
    }

    const [rows, setRows] = useState(savedStrategies);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [showBrokerSelectDialog, setShowBrokerSelectDialog] = useState(false);
    const [tradeLegsOpen, setTradeLegsOpen] = useState(false);
    const [tradeLegs, setTradeLegs] = useState({
        strategyName: "",
        legs: [],
    });
    const [loading, setLoading] = useState(false);
    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [promiseArguments, setPromiseArguments] = useState({
        promiseArgs: null,
        msg: "",
    });
    const [brokerAccountsSelected, setBrokerAccountsSelected] = useState({ finvasia: [], angel: [], zerodha: [] });
    const [isPaperTrade, setIsPaperTrade] = useState(false);

    const navigate = useNavigate();

    const onExecuteBtnClick = () => {
        setShowBrokerSelectDialog(true);
    };

    const onConfirmBtnClick = async () => {
        if (!isPaperTrade && (brokerAccountsSelected.finvasia.length + brokerAccountsSelected.angel.length + brokerAccountsSelected.zerodha.length === 0)) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: 'At least one broker account has to be selected', type: "error" });
            return;
        }
        setLoading(true);
        const axiosInstance = createAxiosInstance(true);
        await axiosInstance.post(`trades/strategy/execute?strategy_id=${rowSelectionModel[0]}&paper_trade=${isPaperTrade}`, !isPaperTrade ? { broker_ids: brokerAccountsSelected } : {}).then(() => {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: "Strategy is in execution", type: "success" });
            setTimeout(() => {
                navigate(0);
            }, 1000);
        }).catch((err) => {
            let msg = '';
            if (err.request.status === 404)
                msg = err.message;
            else if (err.request.status === 401 || err.request.status === 400)
                msg = msg = err.response.data.detail;
            else
                msg = err.message;
            setSnackBarOpen(true);
            setDisplayMsg({ msg: msg, type: "error" });
        }).finally(() => {
            setLoading(false);
            setShowBrokerSelectDialog(false);
            setBrokerAccountsSelected({ finvasia: [], angel: [], zerodha: [] });
        })

    };

    const showStrategyLegDetails = async (id) => {
        var strategy = savedStrategies.find((strategy) => strategy.id === id);
        setTradeLegs({ name: strategy.name, legs: strategy.trades ?? [] });
        setTradeLegsOpen(true);
    };

    const handleDeleteConfirmNo = () => {
        const { resolve, id } = promiseArguments.promiseArgs;
        resolve(id);
        setPromiseArguments({ promiseArgs: null, msg: "" });
    };

    const handleDeleteConfirmYes = async () => {
        const { resolve, id } = promiseArguments.promiseArgs;
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.delete(`/trades/strategy/?id=${id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Strategy deleted successfully", type: "success" });
                setRows(prev => {
                    let newData = [...prev];
                    newData = newData.filter(strategy => strategy.id !== id);
                    return newData;
                })
            }

        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
            resolve(id);
            setPromiseArguments({ promiseArgs: null, msg: "" });
        }
    };

    const receiveSocketData = () => {
        ws.onmessage = ({ data }) => {
            const strategy = JSON.parse(data);
            if (strategy.status === "saved" || strategy.status === "executed") {
                setRows((prevData) => {
                    const newData = [...prevData];
                    const objectIdx = newData.findIndex(
                        (row) => row.id === parseInt(strategy.id)
                    );
                    
                    newData[objectIdx].csop = strategy.csop;
                    newData[objectIdx].pl = strategy.pl;
                    newData[objectIdx].isop = strategy.isop;
                    newData[objectIdx].status = strategy.status;
                    return newData;
                });
            }
        };
    };

    useEffect(() => {
        if (ws)
            receiveSocketData();
    }, []);

    if (loading) {
        return (
            <LoadingBackdrop loading={loading} />
        );
    }

    return (
        <div className="h-full width-full flex flex-col gap-5">
            <StyledDataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none"
                    }
                }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5, page: 0 } },
                }}
                pageSizeOptions={[5, 10, 15]}
                slots={{
                    noRowsOverlay: () => (
                        <NoRowsOverlay msg="You have not created a strategy yet" />
                    ),
                    columnSortedAscendingIcon: () => (
                        <ArrowDownward fontSize="small" sx={{ color: "black" }} />
                    ),
                    columnSortedDescendingIcon: () => (
                        <ArrowUpward fontSize="small" sx={{ color: "black" }} />
                    ),
                    baseCheckbox: (props) => <Radio {...props} size="small" />,
                }}
                disableRowSelectionOnClick
                checkboxSelection
                disableColumnMenu
                getRowClassName={(params) =>
                    moment(params.row.trades[0]?.expiry_date).isBefore(
                        moment().format("YYYY-MM-DD")
                    ) || params.row.status === "placed"
                        ? "strategy-pastExpiry"
                        : ""
                }
                isRowSelectable={(params) =>
                    !moment(params.row.trades[0]?.expiry_date).isBefore(
                        moment().format("YYYY-MM-DD")
                    ) && params.row.status !== "placed"
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel.length === 0 ? [] : [newRowSelectionModel.pop()]);
                }}
                rowSelectionModel={rowSelectionModel}
            />

            <div className="flex justify-between items-end">
                <ColoredButton
                    disabled={!rowSelectionModel.length > 0 || savedStrategies.find(strategy => strategy.id === rowSelectionModel[0])?.status === "running"}
                    variant="contained"
                    size="small"
                    onClick={onExecuteBtnClick}
                >
                    Execute Strategy
                </ColoredButton>

                <h1 className="text-xs italic">
                    *The strategies greyed out either have a past expiry date or is in
                    execution. They cannot be executed.
                </h1>
            </div>

            {showBrokerSelectDialog && (
                <DialogBoxWrapper
                    iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BiLogInCircle /></IconContext.Provider>}
                    open={showBrokerSelectDialog} setOpen={setShowBrokerSelectDialog}
                    title='Choose broker accounts'
                    subtitle='Choose broker to execute strategy'
                    contentComponent={<BrokerSelectDialog setBrokerAccountsSelected={setBrokerAccountsSelected} setIsPaperTrade={setIsPaperTrade} isPaperTrade={isPaperTrade} />}
                    confirmHandler={onConfirmBtnClick}
                />
            )}

            {promiseArguments.promiseArgs && (
                <ConfirmDialog
                    promiseArgs={promiseArguments.promiseArgs}
                    msg={promiseArguments.msg}
                    handleNo={handleDeleteConfirmNo}
                    handleYes={handleDeleteConfirmYes}
                />
            )}

            {tradeLegsOpen && (
                <TradeLegsDetails
                    open={tradeLegsOpen}
                    setOpen={setTradeLegsOpen}
                    tradeLegs={tradeLegs}
                />
            )}

            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    );
}

