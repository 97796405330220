import styled from "@emotion/styled";
import { Check, Clear } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { ButtonGroup, Chip, Menu, MenuItem } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { capitalize } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { IconContext } from 'react-icons';
import { BiLogInCircle } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import Angelone from "../assets/angelone_logo.png";
import Finvasia from "../assets/finvasia_logo.png";
import Zerodha from "../assets/zerodha_logo.png";
import ColoredButton from "../components/common/ColoredButton";
import DialogBoxWrapper from "../components/common/DialogBoxWrapper";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import NoRowsOverlay from "../components/common/NoRowsOverlay";
import Otp from "../components/common/Otp";
import OutlinedButton from "../components/common/OutlinedButton";
import StyledDataGrid from "../components/common/StyledDataGrid";
import TextInput from "../components/common/TextInput";
import { createAxiosInstance } from "../Utils/axiosConfig";

function renderAccountNick({ value }) {
    return (
        <strong>{value}</strong>
    )
}

function renderStatus(props) {
    return (
        props.row.login_token !== 'None' ? <Chip label="Logged in" color="success" variant="outlined" icon={<Check />} /> : <Chip label="Logged out" color="error" variant="outlined" icon={<Clear />} />
    )
}

const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        borderRadius: '10px',
        boxShadow: '9px 9px 20px 0px #95949c',
        '& .MuiMenu-list': {
            padding: '10px 0px',
        },
        '& .MuiMenuItem-root': {
            fontFamily: 'Poppins',
            height: '45px',
            padding: '20px'
        }
    }
})

function BrokerAccountsSetup() {

    const renderLoginBtn = (props) => {
        return (
            props.row.login_token === 'None' && <ColoredButton variant="contained" size="small" onClick={() => {
                setBrokerToLogin(props.row);
                if (props.row.broker === 'zerodha') window.location.href = `https://kite.zerodha.com/connect/login?api_key=${props.row.api_key}`
                props.row.broker !== 'zerodha' && setShowLoginDialog(true);
            }}>Login</ColoredButton>
        )
    }

    const columns = [
        {
            field: 'nickname',
            headerName: 'Account Nickname',
            width: 450,
            renderCell: renderAccountNick,
        },
        {
            field: 'userid',
            headerName: 'Client ID',
            width: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: renderStatus,
            align: 'center',
        },
        {
            field: 'login',
            headerName: 'Action',
            renderCell: renderLoginBtn,
            width: 150,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    onClick={() => handleDeleteBtnClick(params.row)}
                    icon={<IconContext.Provider value={{ color: '#667085', size: '1.2em' }}><FiTrash2 /></IconContext.Provider>}
                />,
                <GridActionsCellItem
                    onClick={() => navigate(`/addBroker/${params.row.broker}`, { state: { accountDetails: params.row, mode: 'edit' } })}
                    icon={<IconContext.Provider value={{ color: '#667085', size: '1em' }}><FiEdit2 /></IconContext.Provider>}
                />
            ]
        }
    ];

    const [brokerSelected, setBrokerSelected] = useState('all');
    const [brokerAccountsSetup, setBrokerAccountsSetup] = useState();
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [brokerAccountToDelete, setBrokerToDelete] = useState(undefined);
    const [brokerToLogin, setBrokerToLogin] = useState(undefined);
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [otp, setOtp] = useState("");
    const [angelistOtp, setAngelistOtp] = useState(new Array(6).fill(""));
    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const showMenu = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleAddBtnClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteBtnClick = (brokerAccount) => {
        setShowDeleteConfirmDialog(true);
        setBrokerToDelete(brokerAccount);
    }

    const handleDeleteBrokerClick = async () => {
        if (brokerAccountToDelete) {
            var axiosInstance = createAxiosInstance(true);
            try {
                setLoading(true);
                const response = brokerAccountToDelete.broker === 'finvasia' ?
                    await axiosInstance.delete(`trades/shoonya/?id=${brokerAccountToDelete.id}`) :
                    brokerAccountToDelete.broker === 'angelist' ?
                        await axiosInstance.delete(`trades/angel/?id=${brokerAccountToDelete.id}`) :
                        await axiosInstance.delete(`trades/zerodha/?id=${brokerAccountToDelete.id}`);

                if (response.status === 200) {
                    setBrokerAccountsSetup(prevData => {
                        var newData = { ...prevData };
                        newData[brokerAccountToDelete.broker] = newData[brokerAccountToDelete.broker].filter(account => account.id !== brokerAccountToDelete.id);
                        return newData;
                    })
                    setSnackBarOpen(true);
                    setDisplayMsg({ msg: 'Broker account deleted successfully', type: 'success' });
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                setShowDeleteConfirmDialog(false);
            }
        }
    }

    const handleBrokerLogin = async () => {
        if ((brokerToLogin.broker === 'finvasia' && (otp === '')) || (brokerToLogin.broker === 'angelist' && (angelistOtp.join("") === '' || angelistOtp.join("").length < 6)))
            return;

        else if (brokerToLogin.broker === 'zerodha') {
            window.location.href = 'https://kite.zerodha.com/connect/login?v=3&api_key=' + brokerToLogin.api_key
        }

        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = brokerToLogin.broker === 'finvasia' ?
                await axiosInstance.post(`trades/shoonya/login?totp=${otp}&broker_id=${brokerToLogin.id}`) :
                await axiosInstance.post(`trades/angel/login?totp=${angelistOtp.join("")}&broker_id=${brokerToLogin.id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account login successful", type: "success" });
                navigate(0);
                setShowLoginDialog(false);
            }

        } catch (err) {
            setSnackBarOpen(true);
            if (err.response?.data?.detail)
                setDisplayMsg({ msg: err.response.data.detail, type: "error" })
            else
                setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
            setOtp("");
            setAngelistOtp(new Array(6).fill(""));
        }
    }

    const handleFinvasiaOtpChange = (e) => {
        const { value } = e.target;
        if ((parseInt(value) >= 0 && parseInt(value) < 999999 && value.length <= 6) || value === "")
            setOtp(value)
    }

    useEffect(() => {
        async function getBrokersSetup() {
            setLoading(true);
            try {
                var axiosInstance = createAxiosInstance(true);
                const brokerResponse = await axiosInstance.post('auth/user/brokers/');
                if (brokerResponse.status === 200) {
                    const data = {};
                    Object.entries(brokerResponse.data).forEach(brokerData => {
                        if(brokerData[0] !== "total_brokers") {
                            data[brokerData[0]] = brokerData[1].map(broker => ({...broker, broker: brokerData[0]}))
                        }
                    })
                    setBrokerAccountsSetup(data);
                }
            } catch (err) {
                // Implement new err mechanism in this page
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
        getBrokersSetup();
    }, [])

    return (
        <section>
            <div className="flex justify-between mb-8">
                <div>
                    <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Broker Account Setup</h1>
                    <p className="text-gray-500 mt-2">Add and manage your broker accounts</p>
                </div>
                <div>
                    <ColoredButton variant="contained" size="small" startIcon={<AddIcon />}
                        aria-controls={showMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={showMenu ? 'true' : undefined}
                        onClick={handleAddBtnClick}
                    >Add
                    </ColoredButton>

                    <StyledMenu
                        anchorEl={anchorEl}
                        open={showMenu}
                        onClose={handleClose}
                        elevation={2}
                    >
                        <MenuItem className="gap-5" onClick={() => navigate('/addBroker/finvasia')}>
                            <img src={Finvasia} alt="Finvasia Logo" height="40px" width="40px" />
                            Finvasia
                        </MenuItem>
                        <MenuItem className="gap-5" onClick={() => navigate('/addBroker/zerodha')}>
                            <img src={Zerodha} alt="Zerodha Logo" height="40px" width="40px" />
                            Zerodha
                        </MenuItem>
                        <MenuItem className="gap-5" onClick={() => navigate('/addBroker/angelist')}>
                            <img src={Angelone} alt="Angelone Logo" height="40px" width="40px" />
                            Angel One
                        </MenuItem>
                    </StyledMenu>
                </div>
            </div>
            <hr />

            <div className="flex justify-center mt-6">
                <ButtonGroup variant="outlined">
                    <OutlinedButton variant={brokerSelected === "all" ? "contained" : "outlined"} onClick={() => setBrokerSelected('all')}>All</OutlinedButton>
                    <OutlinedButton variant={brokerSelected === "zerodha" ? "contained" : "outlined"} onClick={() => setBrokerSelected('zerodha')}>Zerodha</OutlinedButton>
                    <OutlinedButton variant={brokerSelected === "finvasia" ? "contained" : "outlined"} onClick={() => setBrokerSelected('finvasia')}>Finvasia</OutlinedButton>
                    <OutlinedButton variant={brokerSelected === "angelist" ? "contained" : "outlined"} onClick={() => setBrokerSelected('angelist')}>Angel One</OutlinedButton>
                </ButtonGroup>
            </div>

            <Fragment>
                <div className="p-5 flex flex-col gap-5">
                    {brokerSelected !== 'all' && 
                        <div className="flex items-center gap-5">
                            <img src={brokerSelected === 'zerodha' ? Zerodha : brokerSelected === 'finvasia' ? Finvasia : Angelone} alt="Zerodha Logo" height="75px" width="75px" />
                            <h1 className="text-2xl font-medium text-gray-900">{capitalize(brokerSelected)}</h1>
                        </div>
                    }

                    <div className={brokerSelected === "all" ? "h-[450px]" : "h-[300px]"}>
                        {brokerAccountsSetup && <StyledDataGrid
                            columns={columns} rows={brokerSelected === "all" ? Object.values(brokerAccountsSetup).flat() : brokerAccountsSetup[brokerSelected] ? brokerAccountsSetup[brokerSelected] : []}
                            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"}
                            disableRowSelectionOnClick disableColumnMenu checkboxSelection hideFooter
                            slots={{
                                noRowsOverlay: () => (
                                    <NoRowsOverlay msg="No accounts setup" />
                                )
                            }} />}
                    </div>
                </div>
            </Fragment>

            {showDeleteConfirmDialog && <DialogBoxWrapper
                iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BsCheck2Circle /></IconContext.Provider>}
                title={'Are you sure?'}
                contentComponent={'Are you sure you want to delete this broker?'}
                open={showDeleteConfirmDialog}
                setOpen={setShowDeleteConfirmDialog}
                confirmHandler={handleDeleteBrokerClick}
            />
            }

            {showLoginDialog && <DialogBoxWrapper
                iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BiLogInCircle /></IconContext.Provider>}
                open={showLoginDialog} setOpen={setShowLoginDialog}
                title={'Please enter TOTP'}
                subtitle={`Please enter your ${brokerToLogin.broker} account TOTP`}
                contentComponent={brokerToLogin.broker === 'finvasia' ? <TextInput name='finvasiaOtp' value={otp} onChange={handleFinvasiaOtpChange} size="small" /> : <Otp otp={angelistOtp} setOtp={setAngelistOtp} />}
                confirmHandler={handleBrokerLogin}
            />}

            {loading && <LoadingBackdrop loading={loading} />}

            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </section>
    )
}

export default BrokerAccountsSetup;